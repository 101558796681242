import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { getAuth } from "firebase/auth";

import { UserService } from "src/app/services";
import { assertTenantGuid, assertTenantId, assertUserId } from "../helpers";

/**
 * ## Login Guard
 * Check to see if user is already logged in and redirects to their default portal
 */
@Injectable({
  providedIn: "root",
})
export class TokenGuard {
  /** @ignore */
  constructor(private router: Router, private us: UserService) {}
  /** Token guard canActivate handler */
  async canActivate(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ): Promise<boolean> {
    const loggedIn = await this.checkAuthState();

    if (loggedIn) {
      try {
        const ids = await this.us.initUserIds();

        assertUserId(ids.userId);
        assertTenantId(ids.tenantId);
        assertTenantGuid(ids.tenantGuid);

        this.router.navigate(["/", ids.tenantGuid, "projects"]);

        // already logged in, prevent route to /Login
        return false;
      } catch (ex) {
        // failed assertions
        await this.us.logout();
      }
    }

    // not loggedin, goto /Login
    return true;
  }

  async checkAuthState(): Promise<boolean> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user != null) {
          resolve(true);
        }
        resolve(false);
      });
    });
  }
}
