import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { FirebaseService, UserService } from "src/app/services";
import { assertTenantGuid, assertTenantId, assertUserId } from "../helpers";

const billingRedirectGuardFn: CanActivateFn = async (
  snapshot: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
): Promise<boolean> => {
  const us = inject(UserService);
  const fb = inject(FirebaseService);
  const router = inject(Router);

  const productId = snapshot.params["productId"] || "";
  const quantity = snapshot.params["quantity"] || "";
  const code = snapshot.queryParams["code"] || "";

  const loggedIn = await fb.isLoggedIn();

  if (loggedIn) {
    try {
      const ids = await us.initUserIds();

      assertUserId(ids.userId);
      assertTenantId(ids.tenantId);
      assertTenantGuid(ids.tenantGuid);

      router.navigate(["/", ids.tenantGuid, "billing", productId, quantity], {
        queryParams: code ? { code } : {},
      });
      return false;
    } catch (ex) {
      // assertions failed, logout and return to /login
      await us.logout();
    }
  }

  let queryParams: any = {
    mode: "checkout",
  };

  if (productId) {
    queryParams["productId"] = productId;
  }
  if (quantity) {
    queryParams["quantity"] = quantity;
  }
  if (code) {
    queryParams["code"] = code;
  }

  router.navigate(["/login"], { queryParams });
  return false;
};

export const billingRedirectGuard: CanActivateFn = billingRedirectGuardFn;
