import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";

import { FirebaseService, UserService } from "src/app/services";
import { assertTenantGuid, assertTenantId, assertUserId } from "../helpers";

/** Authorisation guard */
@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  /** @ignore */
  constructor(
    private router: Router,
    private us: UserService,
    private readonly fb: FirebaseService
  ) {}
  /** Guard canActive handler */
  async canActivate(
    snapshot: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ): Promise<boolean> {
    const loggedIn = await this.fb.isLoggedIn();

    if (loggedIn) {
      try {
        const routeGuid = snapshot.params["tenantid"];

        const ids = await this.us.initUserIds(routeGuid);

        assertUserId(ids.userId);
        assertTenantId(ids.tenantId);
        assertTenantGuid(ids.tenantGuid);

        return true;
      } catch (ex) {
        // assertions failed, logout and return to /login
        await this.us.logout();
      }
    }

    this.router.navigate(["/login"]);
    return false;
  }
}
